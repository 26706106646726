import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpParams
} from '@angular/common/http';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { Observable, catchError, throwError } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { TermsTypes, ITermsContentResponse } from '../interfaces/terms';

@Injectable()
export class TermsService {
  API_STATIC_CONTENT: string;

  constructor(private appConfigService: AppConfigService, private http: HttpClient) {
    const staticContentApiPrefix = this.appConfigService.serviceUrl(
      ServiceNames.StaticContent,
      'v1'
    );
    this.API_STATIC_CONTENT = staticContentApiPrefix + 'static-content';
  }

  getTermsContent(
    contentType: TermsTypes,
    version?: string
  ): Observable<ITermsContentResponse> {
    const options = {
      params: null
    };

    if(!version) {
      options.params = new HttpParams()
        .set('type', contentType || TermsTypes.full)
    } else {
      options.params = new HttpParams()
        .set('type', contentType || TermsTypes.full)
        .set('version', version);
    }

    return this.http
      .get<ITermsContentResponse>(`${this.API_STATIC_CONTENT}/terms/content`, options)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          console.error(
            `[TermsService] Failed to retrieve Terms content for contentType ${contentType} and version ${version}`,
            error
          );
          return throwError(() => error);
        })
      );
  }
}
